import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from 'src/actions';
import Header from "src/components/header/Header";
import SwiperInvestmentsAndWidrawals from './SwiperInvestmentsAndWidrawals';

class InvestmentAndWidrawals2 extends Component {
  static propTypes = {
    portfolioId: PropTypes.string,
    match: PropTypes.object.isRequired,
    in_outs: PropTypes.object,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    header: PropTypes.object
  }

  render() {
    const {
      in_outs, history, user, header, match: {
        params: { portfolioId }
      }
    } = this.props;
    const classNavInfo = { bodyClassName: "page investments" };

    const service = user.services.find((service) => {
      return (service.id.toString() === portfolioId.toString());
    });
    const title = service.code_publish;

    return (
      <Header
        authenticated
        history={history}
        user={user}
        classNavInfo={classNavInfo}
        portfolioId={portfolioId}
        activateSwiper
        activateButtonBack
        advisor={user.advisor}
        title={title}
      >
        <SwiperInvestmentsAndWidrawals
          in_outs={in_outs}
          headerInfo={header}
          advisor={user.advisor}
          user={user}
          history={history}
          portfolioId={portfolioId}
          classNavInfo={classNavInfo}
        />
      </Header>
    );
  }
}

// export default InvestmentAndWidrawals2;

//export default Historical;
function mapStateToProps(state) {
  const { in_outs } = state.fundData.data;
  const { user, header } = state.auth.data;
  return { in_outs, user, header };
}
export default connect(
  mapStateToProps,
  actions
)(InvestmentAndWidrawals2);
