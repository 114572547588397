
import Numeral from 'numeral';
import _ from 'lodash';

const currencyFormat = function (amount, currencyCode, showDecimal = true) {
  let numAmount;
  if (_.isNil(amount)) {
    return;
  }
  if (_.isNumber(amount)) {
    numAmount = new Number(amount.toString());
  } else if (_.isString(amount)) {
    numAmount = new Number(amount);
  }

  let formattedAmountIn;
  if (currencyCode) {
    formattedAmountIn = numAmount.toLocaleString('de-DE', { style: 'currency', currency: currencyCode });
  } else {
    const decimalConfig = (showDecimal) ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    formattedAmountIn = numAmount.toLocaleString('de-DE', decimalConfig);
  }
  return formattedAmountIn;
};
const percentageFormat = function (stringAmount) {
  const divisedWeight = Number(stringAmount) / 100;
  const formattedWeight = new Numeral(divisedWeight).format('0.00 %');
  return formattedWeight;
};
export { currencyFormat, percentageFormat };
