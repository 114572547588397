import { TIMER_TIME_OUT, TIMER_START_TIMER } from '../actions/types';

const INITIAL_STATE = { timer: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case TIMER_TIME_OUT:
      return {
        ...state
      };
    case TIMER_START_TIMER:
      return {
        ...state
      };

    default:
      return state;
  }
}
