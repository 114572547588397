import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from 'src/actions';
import Header from 'src/components/header/Header';
import { store } from 'src/reduxStore';
import SwiperFundsListComponent from './SwiperFundsListComponent';


class FundsList extends Component {
  static propTypes = {
    history: PropTypes.object,
    portfolioList: PropTypes.array,
    headerInfo: PropTypes.object,
    advisor: PropTypes.object,
    user: PropTypes.object,
  }

  componentDidMount() {
    // TODO (DR 9/3/19): This needs to move somewhere else. This
    // click handler gets reattached on every visit to the FundsList screen.
    window.addEventListener("click", (e) => {
      e.stopPropagation();
      store.dispatch(actions.timerSet(actions.getTimeOutforSignOut()));
    });
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      history, portfolioList, headerInfo, advisor, user
    } = this.props;

    if (!portfolioList || !headerInfo) {
      return <div>error loading data</div>;
    }
    const classNavInfo = { bodyClassName: "page home" };

    return (
      <Header
        authenticated
        advisor={advisor}
        history={history}
        user={user}
        classNavInfo={classNavInfo}
        activateSwiper
        title="Home"
      >
        <SwiperFundsListComponent
          portfolioList={portfolioList}
          headerInfo={headerInfo}
          advisor={advisor}
          history={history}
          user={user}
          classNavInfo={classNavInfo}
        />
      </Header>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.auth.message,
    headerInfo: state.auth.data.header,
    portfolioList: state.auth.data.user.services,
    user: state.auth.data.user,
    advisor: state.auth.data.user.advisor,
  };
}
export default connect(mapStateToProps, actions)(FundsList);
