import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from "src/actions";
import Header from "src/components/header/Header";
import * as navigationActions from 'src/actions/NavigationActions';
import SwiperFundDescriptionComponent from "./SwiperFundDescriptionComponent";

class FundsDescription extends Component {
  static propTypes = {
    history: PropTypes.object,
    goBack: PropTypes.func,
    match: PropTypes.object,
    loadFundDescData: PropTypes.func,
    data: PropTypes.object,
    user: PropTypes.object,
    header: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.swiper = null;
  }

  componentDidMount() {
    const {
      match: {
        params: { portfolioId }
      },
      loadFundDescData,
      user
    } = this.props;

    loadFundDescData(portfolioId, user);
  }

  // TODO: move into SwiperFundDescriptionComponent
  linkToDrillDown = (e, location) => {
    e.preventDefault();
    const { history } = this.props;
    navigationActions.navigateTo(history, location);
  }

  render() {
    const {
      user, history, data, header
    } = this.props;
    const { match: { params: { portfolioId } } } = this.props;

    if (!data) {
      return <div>Loading ...</div>;
    }

    const classNavInfo = { bodyClassName: "page detail" };

    return (
      <Header
        authenticated
        title={data.code_publish}
        history={history}
        user={user}
        classNavInfo={classNavInfo}
        portfolioId={portfolioId}
        activateSwiper
      >
        <SwiperFundDescriptionComponent
          data={data}
          headerInfo={header}
          user={user}
          history={history}
          portfolioId={portfolioId}
          classNavInfo={classNavInfo}
          linkToDrillDown={this.linkToDrillDown}
        />

      </Header>
    );
  }
}

function mapStateToProps(state) {
  const { data } = state.fundData;
  const { user, header } = state.auth.data;
  return { data, user, header };
}
export default connect(
  mapStateToProps,
  actions
)(FundsDescription);
