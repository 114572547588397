/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from "prop-types";
import _ from 'lodash';
import { connect } from 'react-redux';
import * as actions from 'src/actions';
import Header from "src/components/header/Header";
import { HeaderTime } from "src/components/headerTime/headerTime";
import { CurrencyComponent, PercentageComponent } from 'src/utils/NumberTDStyle';
import * as navigationActions from 'src/actions/NavigationActions';
import { formatDate } from 'src/utils/DateFormat';


class Historical extends PureComponent {
  static propTypes = {
    portfolioId: PropTypes.string,
    match: PropTypes.object.isRequired,
    loadFundDescData: PropTypes.func.isRequired,
    historicals: PropTypes.array,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    header: PropTypes.object,
  }

  componentDidMount() {
    const {
      match: {
        params: { portfolioId }
      },
      loadFundDescData,
      user
    } = this.props;

    loadFundDescData(portfolioId, user);
  }

  renderLastYear(historicals) {
    if (!_.isNil(historicals) && historicals.length > 0) {
      return (
        <div className="current-period">
          <div className="year">
            {formatDate(historicals[0].valuation_date)}
          </div>
          <div className="period">
            <span>&nbsp;</span>
          </div>
          <CurrencyComponent
            className="amount"
            amount={historicals[0].amount}
            strong={false}
            type="div"
            currencyCode="EUR"
          />
          <PercentageComponent
            strong={false}
            amount={historicals[0].performance}
            type="div"
          />
        </div>
      );
    }
  }

  renderPreviousYears(historicals) {
    if (!_.isNil(historicals) && historicals.length > 1) {
      const nodeArray = [];
      for (let index = 1; index < historicals.length; index++) {
        const element = historicals[index];

        const node = (
          <tr>
            <td className="year-percent">
              <div className="year">
                {element.valuation_date.substr(0, 4)}
              </div>
              <PercentageComponent
                strong={false}
                amount={element.performance}
                type="div"
              />
            </td>
            <CurrencyComponent
              strong={false}
              amount={element.amount}
              className="amount"
              type="td"
              currencyCode="EUR"
            />
          </tr>

        );
        nodeArray.push(node);
      }

      return React.createElement('tbody', null, ...nodeArray);
    }
  }

  linkToAdvisor = (e, location) => {
    e.preventDefault();
    const { history } = this.props;
    navigationActions.navigateTo(history, location);
  }

  render() {
    const {
      historicals, history, user, header, match: {
        params: { portfolioId }
      }
    } = this.props;
    const classNavInfo = { bodyClassName: "page historical" };
    const classNavInfo_advisor = { bodyClassName: "page advisor" };

    const service = user.services.find((service) => {
      return (service.id.toString() === portfolioId.toString());
    });
    const title = service.code_publish;

    return (
      <Header
        authenticated
        history={history}
        user={user}
        classNavInfo={classNavInfo}
        activateSwiper={false}
        portfolioId={portfolioId}
        activateButtonBack
        title={title}
      >
        <div className="main-container">
          <div className="wrapper clearfix">
            <h1>Historical Performance</h1>

            <HeaderTime header={header} />
            {this.renderLastYear(historicals)}
            <div className="all-periods">
              <table>
                {this.renderPreviousYears(historicals)}
              </table>
            </div>
          </div>
        </div>


        <div
          className="advisor-link"
        >
          <a
            onClick={(e) => {
              this.linkToAdvisor(e, {
                pathname: `/advisor/${portfolioId}`,
                state: { classNavInfo: classNavInfo_advisor }
              });
            }}
          >
            <img src={user.advisor.image_url} alt="Advisor" />
            <span>Your Advisor</span>
          </a>
        </div>
      </Header>
    );
  }
}

function mapStateToProps(state) {
  const { historicals } = state.fundData.data;
  const { user, header } = state.auth.data;
  return { historicals, user, header };
}
export default connect(
  mapStateToProps,
  actions
)(Historical);
