export const styles = {
  advisorContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    width: '100%',
    //borderStyle: 'solid',
    //borderColor: 'red',
    //borderWidth: '1px'
  },
  profileStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    width: '50%'
  },
  dataStyle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    //borderStyle: 'solid',
    //borderColor: 'green',
    //borderWidth: '1px',
    width: '50%'
  },
  titleStyle: {
    display: 'flex',
    fontSize: 16,
    color: 'blue'
  },
  imageStyle: {
    display: 'flex',
    borderRadius: '50%',
    maginBottom: 30
  },
  imageContainerStyle: {
    display: 'flex',
    // borderWidth: '1px',
    // borderStyle: 'solid',
    // borderColor: 'red'
    padding: 40
  },
  nameStyle: {
    display: 'flex',
    fontSize: 25
  },
  messageContainerStyle: {
    display: 'flex',
    marginTop: 40,
  },
  textAreaContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20
  },
  formStyle: {
    display: 'flex',
    width: '400px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textAreaFileldStyle: {
    display: 'flex',
    width: '380px',
  },
  buttonSubmitStyle: {
    display: 'flex',
    width: '120px',
    fontSize: 14,
    marginTop: 30
  },
  messageOkStyle: {
    display: 'flex',
    fontSize: 18,
    //marginTop: 20
    padding: 40
  },
  messageResultSyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 30
  },
  messageFormStyle: {
    display: 'felx',
    padding: 30
  }
};
