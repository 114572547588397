export const AUTH_USER = 'auth_user';
export const UNAUTH_USER = 'unauth_user';
export const AUTH_ERROR = 'auth_error';
export const FETCH_MESSAGE = 'fetch_message';
export const LOAD_DATA_FUND = 'load_data_fund';
export const LOAD_DATA_ASSET = 'load_data_asset';
export const LOAD_PUBLIC_FUNDS = 'load_public_funds';
export const LOAD_USER_NAME = 'LOAD_USER_NAME';
export const POST_MESSAGE_TO_ADVISOR = 'POST_MESSAGE_TO_ADVISOR';
export const TIMER_TIME_OUT = 'TIMER_TIME_OUT';
export const TIMER_START_TIMER = 'TIMER_START_TIMER';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_OK = 'RESET_PASSWORD_OK';
