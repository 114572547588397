/* eslint-disable no-else-return */
import * as navigationActions from 'src/actions/NavigationActions';

import { URLS } from 'env.js';
import axios from 'axios';
import {
  AUTH_USER, UNAUTH_USER, AUTH_ERROR, LOAD_DATA_FUND, LOAD_DATA_ASSET, LOAD_USER_NAME, RESET_PASSWORD_ERROR, RESET_PASSWORD_OK
} from './types';

let timer = null;
let refreshTokenTimer = null;
let timerTimeOutInMillis = null;

export const getTimeOutforSignOut = () => {
  return Number(timerTimeOutInMillis);
};

export function showTimer() {
  return timer;
}
export function clearTimer() {
  // clear resetToken timeout
  clearInterval(timer);
}


export function signOutUser() {
  sessionStorage.removeItem('advisor');
  sessionStorage.removeItem('data');
  sessionStorage.removeItem('token');

  timer = null;
  return {
    type: UNAUTH_USER
  };
}

export function timeOutSignOut() {
  sessionStorage.removeItem('advisor');
  sessionStorage.removeItem('data');
  sessionStorage.removeItem('token');

  timer = null;
  window.location.reload();
  return {
    type: UNAUTH_USER
  };
}

function timeOutReached() {
  clearInterval(timer);
  return function (dispatch) {
    dispatch(timeOutSignOut());
  };
}

export function goAndRefreshToken() {
  return function (dispatch) {
    clearInterval(refreshTokenTimer);

    const token = (sessionStorage.getItem('token'));
    const config = {
      headers: { Authorization: `bearer ${token}` }
    };
    const bodyParameters = {
      message: ''
    };

    const url = `${URLS.ROOT_URL_PROD}/${URLS.TOKEN_REFRESH}`;

    axios.post(url, bodyParameters, config)
      .then((response) => {
        return response.data.data;
      })
      .then((data) => {
        sessionStorage.setItem('token', data.access_token);
        const timeOutFromServerInMillis = Number(data.expires_in_seconds) * 1000;
        const thirtySeconds = 30 * 1000;
        const resetTime = Number(timeOutFromServerInMillis) - Number(thirtySeconds);
        refreshTokenTimer = setInterval(() => dispatch(goAndRefreshToken()), resetTime);
      }).catch((err) => {
        console.error(err);
      });
  };
}

export function timerSet(milliseconds) {
  if (milliseconds > 1000) {
    return function (dispatch) {
      clearInterval(timer);
      timer = setInterval(() => dispatch(timeOutReached()), milliseconds);
    };
  } else {
    return {
      type: 'NO_ACTION',
      payload: null
    };
  }
}

export function checkIfIsLoggedIn() {
  return function (dispatch) {
    const token = sessionStorage.getItem('token');
    if (token) {
      // We need to update the application state
      dispatch({ type: AUTH_USER });
    } else {
      console.error('error');
    }
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error
  };
}

export function resetPassword({ password, password_confirmation }) {
  const access_token = sessionStorage.getItem('token');

  return function (dispatch) {
    const config = {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    };

    const bodyParameters = {
      password,
      password_confirmation
    };

    axios.post(`${URLS.ROOT_URL_PROD}/${URLS.RESET_PASSWORD}`, bodyParameters, config)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        dispatch({ type: RESET_PASSWORD_OK });
      }).catch((err) => {
        dispatch({ type: RESET_PASSWORD_ERROR, err });
        console.error('error reseting password', err);
      });
  };
}

export function setRememberMe(rememberUserNameValue) {
  return function (dispatch) {
    const userName = '';
    const changedValue = !rememberUserNameValue;
    localStorage.setItem('rememberUserNameValue', changedValue);

    const data = {
      userName
    };
    const loadData = {
      data,
      rememberUserNameValue: changedValue
    };

    dispatch({ type: LOAD_USER_NAME, payload: loadData });
  };
}


export function loadRemeberMe() {
  return function (dispatch) {
    const userName = localStorage.getItem('userName');
    const rememberUserNameValue = JSON.parse(localStorage.getItem('rememberUserNameValue'));

    const data = {
      userName
    };
    const loadData = {
      data,
      rememberUserNameValue
    };

    if (rememberUserNameValue) {
      dispatch({ type: LOAD_USER_NAME, payload: loadData });
    } else {
      loadData.data.userName = '';
      dispatch({ type: LOAD_USER_NAME, payload: loadData });
    }
  };
}


export function logInUser({
  userName, password, history, rememberUserNameValue
}) {
  // I'm using redux-thunk so we have a dispatch
  return function (dispatch) {
    // Submit email, password to the server

    // clear resetToken timeout
    clearInterval(timer);

    let resetPassword = false;

    axios.post(`${URLS.ROOT_URL_PROD}/${URLS.LOGIN}`, { email: userName, password })
      //axios.post(authOptions)
      .then((response) => {
        //Test de reset password
        //response.data.data.reset_password = true;
        return response.data.data;
      })
      .then((data) => {
        // if data.reset_password is true
        // the backend force the front to
        // reset the password
        // - Update the state to indicate user is authenticated
        // - Save the JWT token
        sessionStorage.setItem('token', data.access_token);

        // setting the sign out Timeout
        timerTimeOutInMillis = Number(data.expires_in_seconds) * 1000;

        if (rememberUserNameValue) {
          localStorage.setItem('userName', userName);
        } else {
          localStorage.setItem('userName', '');
        }
        if (data.reset_password) {
          resetPassword = true;
        }

        const config = {
          headers: { Authorization: `Bearer ${data.access_token}` }
        };

        const bodyParameters = {
          key: 'value'
        };

        // seteando el timer para refrescar el token jwt
        const timeOutFromServerInMillis = data.expires_in_seconds * 1000;
        const thirtySeconds = 30 * 1000;
        const resetTime = Number(timeOutFromServerInMillis) - Number(thirtySeconds);
        refreshTokenTimer = setInterval(() => dispatch(goAndRefreshToken()), resetTime);

        // obteniendo la data con el token jwt
        axios.post(`${URLS.ROOT_URL_PROD}/${URLS.FETCH_DATA_PROD}`, bodyParameters, config)
          .then((response) => {
            //Test de reset password
            //response.data.data.reset_password = true;
            return response.data.data;
          })
          .then((data) => {
            const clearHistory = true;
            if (resetPassword) {
              //dispatch({ type: AUTH_USER });
              dispatch({ type: AUTH_USER, data });
              const location = {
                pathname: '/resetPassword'
              };
              navigationActions.navigateTo(history, location, clearHistory);
            } else if (!history.location.pathname.includes('/fundsList')) {
              dispatch({ type: AUTH_USER, data });
              // history.push('/fundsList');
              const location = {
                pathname: '/fundsList'
              };
              const clearHistory = true;
              navigationActions.navigateTo(history, location, clearHistory);
            }
          }).catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
        // If reques is bad...
        // - Show an error to the user
        dispatch(authError(' Bad login info'));
      });
  };
}

export function loadFundDescData(fundId, user) {
  const data = user.services.find((fund) => {
    if (fund.id.toString() === fundId.toString()) {
      return true;
    } else {
      return false;
    }
  });
  return function (dispatch) {
    dispatch({ type: LOAD_DATA_FUND, data });
  };
}

export function loadAssetData(serviceId, detailId, assetId, user) {
  const service = user.services.find((service) => {
    if (service.id.toString() === serviceId.toString()) {
      return true;
    } else {
      return false;
    }
  });

  const detail = service.details.find((detail) => {
    if (detail.id.toString() === detailId.toString()) {
      return true;
    } else {
      return false;
    }
  });

  const asset = detail.items.find((item) => {
    if (item.asset_id.toString() === assetId.toString()) {
      return true;
    } else {
      return false;
    }
  });

  return function (dispatch) {
    dispatch({ type: LOAD_DATA_ASSET, data: asset });
  };
}
