import { POST_MESSAGE_TO_ADVISOR } from '../actions/types';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case POST_MESSAGE_TO_ADVISOR:
      return {
        ...state,
        error: '',
        authenticated: true,
        success: action.payload
      };
    default:
      return state;
  }
}
