import React from 'react';
import ReactDOM from 'react-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import TimeOutProvider from 'src/provider/TimeOutProvider';
import { Root } from './Root';
import { getRoutes } from './Routes';
//import './index.css';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

ReactDOM.render(
  <Root>
    <TimeOutProvider>
      {getRoutes()}
    </TimeOutProvider>
  </Root>,
  document.getElementById('root')
);

/**
 * Required for Apache Cordova
 */
const app = {
  // Application Constructor
  initialize() {
    document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
  },

  // deviceready Event Handler
  //
  // Bind any cordova events here. Common events are:
  // 'pause', 'resume', etc.
  onDeviceReady() {
    this.receivedEvent('deviceready');
  },

  // Update DOM on a Received Event
  receivedEvent(id) {
    const parentElement = document.getElementById(id);
    const listeningElement = parentElement.querySelector('.listening');
    const receivedElement = parentElement.querySelector('.received');

    listeningElement.setAttribute('style', 'display:none;');
    receivedElement.setAttribute('style', 'display:block;');

    console.log(`Received Event: ${id}`);
  }
};

app.initialize();
