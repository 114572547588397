/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { percentageFormat, currencyFormat } from 'src/utils/NumberFormat';

export const CurrencyComponent = (props) => {
  const {
    amount, strong, type, className, currencyCode = "EUR"
  } = props;
  let amountClass;
  let amountVar;
  const classArray = [];
  let classArrayString = '';

  if (className) {
    classArray.push(className);
  }
  if (_.isString(amount)) {
    amountVar = new Number(amount);
  } else if (_.isNumber(amount)) {
    amountVar = amount;
  }

  if (_.isNumber(amountVar) && amountVar >= 0) {
    amountClass = 'positive';
  } else if (_.isNumber(amountVar) && amountVar < 0) {
    amountClass = 'negative';
  }
  classArray.push(amountClass);
  if (classArray.length > 1) {
    classArrayString = classArray.reduce((valAnterior, valActual) => {
      const a = valAnterior.concat(' ');
      return a.concat(valActual);
    }, '');
  } else {
    classArrayString = amountClass;
  }

  if (strong) {
    if (type.toLowerCase() === 'div') {
      return (
        <div className={classArrayString}><strong>{currencyFormat(amountVar, currencyCode)}</strong></div>
      );
    } else if (type.toLowerCase() === 'td') {
      return (
        <td className={classArrayString}><strong>{currencyFormat(amountVar, currencyCode)}</strong></td>
      );
    }
  } else {
    if (type.toLowerCase() === 'div') {
      return (
        <div className={classArrayString}>{currencyFormat(amountVar, currencyCode)}</div>
      );
    } else if (type.toLowerCase() === 'td') {
      return (
        <td className={classArrayString}>{currencyFormat(amountVar, currencyCode)}</td>
      );
    }
  }
};

export const PercentageComponent = (props) => {
  const {
    amount, strong, type, className
  } = props;
  let amountClass;
  let amountVar;
  const classArray = [];
  let classArrayString = '';

  if (className) {
    classArray.push(className);
  }

  if (_.isString(amount)) {
    amountVar = new Number(amount);
  } else if (_.isNumber(amount)) {
    amountVar = amount;
  }

  if (_.isNumber(amountVar) && amountVar >= 0) {
    amountClass = 'percentage positive';
  } else if (_.isNumber(amountVar) && amountVar < 0) {
    amountClass = 'percentage negative';
  }

  classArray.push(amountClass);
  if (classArray.length > 1) {
    classArrayString = classArray.reduce((valAnterior, valActual) => {
      const a = valAnterior.concat(' ');
      return a.concat(valActual);
    }, '');
  } else {
    classArrayString = amountClass;
  }
  if (strong) {
    if (type.toLowerCase() === 'div') {
      return (
        <div className={classArrayString}><strong>{percentageFormat(amountVar)}</strong></div>
      );
    } else {
      return (
        <td className={classArrayString}><strong>{percentageFormat(amountVar)}</strong></td>
      );
    }
  } else {
    if (type.toLowerCase() === 'div') {
      return (
        <div className={classArrayString}>{percentageFormat(amountVar)}</div>
      );
    } else {
      return (
        <td className={classArrayString}>{percentageFormat(amountVar)}</td>
      );
    }
  }
};
PercentageComponent.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  strong: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};

CurrencyComponent.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  strong: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired
};
