/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-else-return */
/* eslint-disable no-return-assign */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
import * as consts from "src/utils/consts";
import { HeaderTime } from 'src/components/headerTime/headerTime';
import { CurrencyComponent } from 'src/utils/NumberTDStyle';
import * as navigationActions from 'src/actions/NavigationActions';
import { formatDate } from 'src/utils/DateFormat';

class SwiperInvestmentsAndWidrawals extends Component {
  static propTypes = {
    in_outs: PropTypes.object.isRequired,
    headerInfo: PropTypes.object.isRequired,

    portfolioList: PropTypes.array,
    advisor: PropTypes.object,
    history: PropTypes.object,
    data: PropTypes.object,
    autenthicated: PropTypes.bool,
    portfolioId: PropTypes.string.isRequired,
    user: PropTypes.object,
    classNavInfo: PropTypes.object
  };

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.swiper = new global.Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet(index, className) {
          const tabsNames = [consts.SWIPE_YTD, consts.SWIPE_12M, consts.SWIPE_24M];
          const src = `<span class="${className}">${tabsNames[index]}</span>`;
          return src;
        }
      },
      initialSlide: 0,
      autoHeight: true,
      on: {
        init() {
          this.updateAutoHeight();
        },
        click() {
          this.updateAutoHeight();
        }
      }

    });
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  renderItems = (items) => {
    if (items.length > 0) {
      const itemsRender = items.map((item, index) => {
        const keyString = `${item.amount}${index}`;
        console.log("SwiperInvestmentsAndWidrawals -> renderItems -> item", item);
        return (
          <tr key={keyString}>
            <td>{formatDate(item.date)}</td>
            <td>{item.subject}</td>
            <CurrencyComponent
              amount={item.amount}
              strong={false}
              type="td"
              currencyCode="EUR"
            />

          </tr>
        );
      });

      return itemsRender;
    }
  }

  renderTitle = ({ totals }) => {
    const { headerInfo } = this.props;
    console.log("SwiperInvestmentsAndWidrawals -> renderTitle -> totals", totals);
    return (
      <React.Fragment>
        <h1>Inflows & Outflows</h1>
        <HeaderTime header={headerInfo} />
        <div className="totals">
          <table>
            <tbody>
              <tr>
                <td>Total Inflows</td>
                <CurrencyComponent
                  amount={totals.in}
                  strong={false}
                  type="td"
                  currencyCode="EUR"
                />

              </tr>
              <tr>
                <td>Total Outflows</td>
                <CurrencyComponent
                  amount={totals.out}
                  strong={false}
                  type="td"
                  currencyCode="EUR"
                />

              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td>Total</td>
                <CurrencyComponent
                  amount={totals.total}
                  strong={false}
                  type="td"
                  currencyCode="EUR"
                />
              </tr>
            </tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }

  renderPeriod = (option, data) => {
    return (
      <React.Fragment>
        {this.renderTitle(data)}
        {
          !_.isNil(data)
          && !_.isNil(data.items)
          && data.items.length > 0
          && (
            <table className="operations">
              <thead>
                <tr>
                  <th>
                    <span className="display-phone-only">Detail</span>
                    <span className="display-desktop-only-inline">Date</span>
                  </th>
                  <th>
                    <span className="display-desktop-only-inline">Subject</span>
                  </th>
                  <th>Operations</th>
                </tr>
              </thead>
              <tbody>
                {this.renderItems(data.items)}
              </tbody>
            </table>
          )
        }
      </React.Fragment>
    );
  }

  renderTab = (option, data) => {
    if (consts.SWIPE_YTD === option) {
      this.renderPeriod(option, data);
    } else if (consts.SWIPE_12M === option) {
      this.renderPeriod(option, data);
    } else if (consts.SWIPE_24M === option) {
      this.renderPeriod(option, data);
    }
  };

  linkToAdvisor = (e, location) => {
    e.preventDefault();
    const { history } = this.props;
    navigationActions.navigateTo(history, location);
    //history.replace(location);
  }

  render() {
    const {
      user, portfolioId, classNavInfo
    } = this.props;

    const { in_outs } = this.props;
    const in_outs_totals_array = Object.values(in_outs.totals);

    const items_ytd = in_outs.items.filter((item) => {
      return item.in_out_type.name === 'YTD';
    });
    const items_12m = in_outs.items.filter((item) => {
      return item.in_out_type.name === '12M';
    });
    const items_24m = in_outs.items.filter((item) => {
      return item.in_out_type.name === '24M';
    });

    const array_12m = [...items_ytd, ...items_12m];
    const array_24m = [...array_12m, ...items_24m];

    const ARRAY_YTD = items_ytd;
    const ARRAY_24M = array_24m;
    const ARRAY_12M = array_12m;
    const TOTALS_IO_OUTS_YTD = in_outs_totals_array[0];
    const TOTALS_IO_OUTS_12M = in_outs_totals_array[1];
    const TOTALS_IO_OUTS_24M = in_outs_totals_array[2];

    return (
      <React.Fragment>

        <div className="main-container">
          <div className="wrapper clearfix">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {this.renderPeriod(consts.SWIPE_YTD, { totals: TOTALS_IO_OUTS_YTD, items: ARRAY_YTD })}
                </div>
                <div className="swiper-slide">
                  {this.renderPeriod(consts.SWIPE_12M, { totals: TOTALS_IO_OUTS_12M, items: ARRAY_12M })}
                </div>
                <div className="swiper-slide">
                  {this.renderPeriod(consts.SWIPE_24M, { totals: TOTALS_IO_OUTS_24M, items: ARRAY_24M })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="advisor-link"
        >
          <a
            onClick={(e) => {
              this.linkToAdvisor(e, {
                pathname: `/advisor/${portfolioId}`,
                state: { classNavInfo }
              });
            }}
          >
            <img src={user.advisor.image_url} alt="Advisor" />
            <span>Your Advisor</span>
          </a>
        </div>


      </React.Fragment>
    );
  }
}

export default SwiperInvestmentsAndWidrawals;
