import React, { Component } from "react";
import PropTypes from "prop-types";
import { percentageFormat } from "src/utils/NumberFormat";
import { HeaderTime } from "src/components/headerTime/headerTime";
import { PercentageComponent } from 'src/utils/NumberTDStyle';

class TabPerformance extends Component {
  static propTypes = {
    portfolioList: PropTypes.array,
    headerInfo: PropTypes.object,
    advisor: PropTypes.object,
    history: PropTypes.object,
    servicesPerformance: PropTypes.array,
    autenthicated: PropTypes.bool,
    details: PropTypes.array,
    portfolioId: PropTypes.string.isRequired
  }

  renderMonetaryPerformanceAsset = (asset) => {
    return (
      <React.Fragment key={asset.asset_name}>
        <tr>
          <td>{asset.asset_name}</td>
          <PercentageComponent
            amount={asset.contribution}
            strong={false}
            type="td"
          />
        </tr>
      </React.Fragment>
    );
  }

  hocMonetaryAssetItems = (children) => {
    return (
      <div className="asset performance expenses">
        <div className="content">
          <table className="contribution">
            <tbody>
              {children}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderPerformanceAsset = (asset) => {
    const performanceCurrency = `Performance  ${asset.currency_code}`;

    return (
      <div className="asset performance" key={asset.asset_name}>
        <div className="content">
          <div
            className="label"
            style={{ color: asset.asset_type.color, borderColor: asset.asset_type.color }}
          >
            {asset.asset_type.name_publish}
          </div>
          <span className="name">{asset.asset_name}</span>
          <div className="period">YTD</div>
          <table className="performances">
            <tbody>
              {
                // DR: Don't render line if perfornamce_currency is null
                // Fixes bug #2 (https://github.com/gsarle/Fimarge-client-reactJS/issues/2)
                asset.perfornamce_currency !== null
                && (
                  <tr>
                    <td>{performanceCurrency}</td>
                    <PercentageComponent
                      amount={asset.perfornamce_currency}
                      strong={false}
                      type="td"
                    />
                    {
                      /*
                      <td>{percentageFormat(asset.perfornamce_currency)}</td>
                      */
                    }
                  </tr>
                )
              }
              <tr>
                <td>Performance EUR</td>
                <PercentageComponent
                  amount={asset.performance}
                  strong={false}
                  type="td"
                />
                {
                  /*
                  <td>{percentageFormat(asset.performance)}</td>
                  */
                }
              </tr>
            </tbody>
          </table>
          <table className="contribution">
            <tbody>
              <tr>
                <td>Contribution</td>
                <PercentageComponent
                  amount={asset.contribution}
                  strong={false}
                  type="td"
                />
                {
                  /*
                  <td>{percentageFormat(asset.contribution)}</td>
                  */
                }
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  renderItems = (items, functionRenderAsset, hoc) => {
    const children = items.map((asset) => {
      // return this.renderPerformanceAsset(asset);
      return functionRenderAsset(asset);
    });

    if (hoc) {
      return this.hocMonetaryAssetItems(children);
      // eslint-disable-next-line no-else-return
    } else {
      return children;
    }
  }

  renderServiceType = (service) => {
    return (
      <section key={service.id}>
        <div className="d-assets-frame" style={{ borderColor: service.color }}>
          <h2 className="title" style={{ color: service.color }}>
            {service.name}
          </h2>
          <span className="amount">{percentageFormat(service.totals)}</span>
          {service.id !== -1 ? this.renderItems(service.items, this.renderPerformanceAsset, false) : this.renderItems(service.items, this.renderMonetaryPerformanceAsset, true)}
        </div>
      </section>
    );
  }

  render() {
    const { headerInfo, servicesPerformance } = this.props;
    /* eslint-disable no-debugger */
    debugger;

    const order = [5, 2, 3, 1, 4, 6, 0];

    const sortedServices = servicesPerformance.sort((a, b) => {
      const indexA = order.indexOf(a.id) !== -1 ? order.indexOf(a.id) : order.length;
      const indexB = order.indexOf(b.id) !== -1 ? order.indexOf(b.id) : order.length;
      return indexA - indexB;
    });

    const result = sortedServices.map(service => {
      return this.renderServiceType(service);
    });

    return (
      <React.Fragment>
        {headerInfo && <HeaderTime header={headerInfo} />}
        {servicesPerformance.length > 0 && result}
      </React.Fragment>
    );
  }
}

export default TabPerformance;
