import _ from 'lodash';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import * as actions from '../../actions';

const FIELDS = {
  email: {
    type: 'input',
    label: 'email'
  },
  password: {
    type: 'input',
    label: 'password'
  },
  passwordConfirm: {
    type: 'input',
    label: 'password confirmation'
  }
};

class SignUp extends PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func,
    errorMessage: PropTypes.string,
    signUpUser: PropTypes.func
  }


  handleFormSubmit({ email, password, passwordConfirm }) {
    const { signUpUser } = this.props;
    signUpUser({ email, password, passwordConfirm });
  }

  renderField = ({
    input, label, type, meta: { touched, error }
  }) => {
    return (
      <div className={`form-group ${error && touched && 'has-danger'}`}>
        <label>{label}</label>
        <input {...input} type={type} className="form-control" />
        <div className="form-control-feedback">
          {touched ? error : ''}
        </div>
      </div>
    );
  }


  renderAlert() {
    const { errorMessage } = this.props;
    if (errorMessage) {
      return (
        <div className="alert alert-danger">
          <strong>Ooops!</strong>
          {errorMessage}
        </div>
      );
    }
  }

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.handleFormSubmit.bind(this))}>
        <Field name="email" component={this.renderField} label="Email" />
        <Field name="password" component={this.renderField} type="password" label="Password" />
        <Field name="passwordConfirm" component={this.renderField} type="password" label="Password Confirmation" />
        {this.renderAlert()}
        <button type="submit" action="submit" className="btn btn-primary">SignIn</button>
      </form>

    );
  }
}

function validate(values) {
  const errors = {};

  _.each(FIELDS, (type, field) => {
    if (!values[field]) {
      errors[field] = `Enter a ${field}`;
    }
  });

  if (values.password !== values.passwordConfirm) {
    errors.password = 'password must match password confirm';
  }

  return errors;
}

function mapStateToProps(state) {
  return { errorMessage: state.auth.error };
}
const ComponentWithForm = reduxForm({
  form: 'SignUpForm',
  validate
})(SignUp);

export default connect(mapStateToProps, actions)(ComponentWithForm);
