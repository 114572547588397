import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends Component {
  render() {
    const { component: Component, authenticated, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => (authenticated === true
          ? <Component {...props} />
          : <Redirect to={{ pathname: '/signin' }} />)}
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  authenticated: PropTypes.bool,
  location: PropTypes.object
};

function mapStateToProps(state) {
  return { authenticated: state.auth.authenticated };
}

export default connect(mapStateToProps)(PrivateRoute);
