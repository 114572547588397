/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { HeaderTime } from 'src/components/headerTime/headerTime';
import * as consts from 'src/utils/consts';
import * as navigationActions from 'src/actions/NavigationActions';
import PortFolioComponent from './PortFolioComponent';

class SwiperFundsListComponent extends Component {
  static propTypes = {
    portfolioList: PropTypes.array.isRequired,
    headerInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    user: PropTypes.object,
    classNavInfo: PropTypes.object,
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.swiper = new global.Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet(index, className) {
          const tabsNames = [consts.SWIPE_MTD, consts.SWIPE_YTD, consts.SWIPE_12M];
          const src = `<span class="${className}">${tabsNames[index]}</span>`;
          return src;
        },
      },
      initialSlide: 1,
      autoHeight: true,
      on: {
        init() {
          this.updateAutoHeight();
        },
        click() {
          this.updateAutoHeight();
        },
      },
    });
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }


  renderPeriod(period) {
    const {
      portfolioList, headerInfo, history,
    } = this.props;

    let performance;
    let globalSection;
    let performancePeriod;

    switch (period) {
      case consts.SWIPE_12M:
        performancePeriod = "performance_12m";
        break;
      case consts.SWIPE_MTD:
        performancePeriod = "performance_mtd";
        break;
      case consts.SWIPE_YTD:
        performancePeriod = "performance_ytd";
        break;

      default:
        break;
    }

    if (portfolioList && portfolioList.length > 0) {
      if (portfolioList[0].is_aggregate === 1) {
        globalSection = (
          <section>
            <PortFolioComponent
              key="isAggregate"
              nameOfClass="portfolio global"
              period={period}
              headerInfo={headerInfo}
              history={history}
              portfolio={portfolioList[0]}
              performancePeriod={performancePeriod}
            />
          </section>
        );
      }
    }
    const portfolioListSection = portfolioList.map((portfolio) => {
      if (portfolio.is_aggregate !== 1) {
        return (
          <PortFolioComponent
            key={portfolio.id}
            nameOfClass="portfolio"
            period={period}
            percentage={performance}
            headerInfo={headerInfo}
            history={history}
            portfolio={portfolio}
            performancePeriod={performancePeriod}
          />
        );
      }
      return <React.Fragment key={portfolio.id} />;
    });

    return (
      <React.Fragment>
        <HeaderTime header={headerInfo} />
        {globalSection}
        <section>
          {portfolioListSection}
        </section>
      </React.Fragment>
    );
  }

  linkToAdvisor = (e, location) => {
    e.preventDefault();
    const { history } = this.props;
    navigationActions.navigateTo(history, location);
  }

  render() {
    const {
      user, classNavInfo
    } = this.props;

    return (
      <React.Fragment>
        <div className="main-container">
          <div className="wrapper clearfix">
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  {
                    this.renderPeriod(consts.SWIPE_MTD)
                  }
                </div>
                <div className="swiper-slide">
                  {
                    this.renderPeriod(consts.SWIPE_YTD)
                  }
                </div>
                <div className="swiper-slide">
                  {
                    this.renderPeriod(consts.SWIPE_12M)
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="advisor-link"
        >
          <a
            onClick={(e) => {
              this.linkToAdvisor(e, {
                pathname: '/advisor',
                state: { classNavInfo }
              });
            }}
          >
            <img src={user.advisor.image_url} alt="Advisor" />
            <span>Your Advisor</span>
          </a>
        </div>
      </React.Fragment>
    );
  }
}

export default SwiperFundsListComponent;
