import axios from 'axios';
import { URLS } from 'env.js';
import {
  POST_MESSAGE_TO_ADVISOR,
} from './types';


export function postMessageToAdvisor(message) {
  return function (dispatch) {
    const token = (sessionStorage.getItem('token'));
    const config = {
      headers: { Authorization: `bearer ${token}` },
    };
    const bodyParameters = { message };

    axios.post(`${URLS.ROOT_URL_PROD}/${URLS.POST_MESSAGE_TO_ADVISOR_URL}`, bodyParameters, config)
      .then((response) => {
        dispatch({
          type: POST_MESSAGE_TO_ADVISOR,
          payload: response.data,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
