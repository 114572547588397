/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'src/components/header/Header';
import { percentageFormat, currencyFormat } from 'src/utils/NumberFormat';
import * as navigationActions from 'src/actions/NavigationActions';
import { HeaderTime } from "src/components/headerTime/headerTime";
import { connect } from 'react-redux';
import * as actions from "src/actions";


class DrillDownDetail extends Component {
  static propTypes = {
    history: PropTypes.object,
    goBack: PropTypes.func,
    match: PropTypes.object,
    location: PropTypes.object,
    state: PropTypes.object,
    hideHeader: PropTypes.bool,
    user: PropTypes.object,
    classNavInfo: PropTypes.object,
    header: PropTypes.object,
    loadAssetData: PropTypes.func,
    asset: PropTypes.object
  }

  componentWillMount() {
    const {
      match: {
        params: { portfolioId, detailId, assetId }
      },
      loadAssetData,
      user
    } = this.props;

    loadAssetData(portfolioId, detailId, assetId, user);
  }

  renderDrillDown = (item, index) => {
    const keyString = `${item.asset_name}${index}`;
    return (
      <tr key={keyString}>
        <td>
          <div className="label" style={{ color: item.asset_type.color, borderColor: item.asset_type.color }}>
            {item.asset_type.name_publish}
          </div>
        </td>
        <td>{item.asset_name}</td>
        <td>{percentageFormat(item.asset_weight)}</td>
      </tr>
    );
  }

  renderAsset() {
    const { asset } = this.props;

    const result = asset.drilldown.map((item) => {
      return this.renderDrillDown(item);
    });
    return result;
  }

  renderDetail = () => {
    const { header, asset } = this.props;

    return (
      <div className="main-container">
        <div className="wrapper clearfix">
          <h1>{asset.asset_name}</h1>
          <div className="total-amount">
            {currencyFormat(asset.asset_amount, asset.asset_currency_code)}
          </div>
          <HeaderTime header={header} />
          <div className="assets">
            <table>
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>
                    <span className="display-desktop-only-inline">Subject</span>
                  </th>
                  <th>Weight</th>
                </tr>
              </thead>
              <tbody>
                {this.renderAsset()}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }

  linkToAdvisor = (e, location) => {
    e.preventDefault();
    const { history } = this.props;
    navigationActions.navigateTo(history, location);
  }

  render() {
    const { match: { params: { portfolioId } } } = this.props;
    const {
      history, hideHeader, user, asset
    } = this.props;
    const classNavInfo = { bodyClassName: "page fund" };

    if (!asset) {
      return <div>Loading ...</div>;
    }

    if (!hideHeader) {
      return (
        <Header
          authenticated
          user={user}
          history={history}
          portfolioId={portfolioId}
          classNavInfo={classNavInfo}
          activateButtonBack
          title="Fund detail"
          activateSwiper={false}
        >
          {this.renderDetail()}
          <div
            className="advisor-link"
          >
            <a
              onClick={(e) => {
                this.linkToAdvisor(e, {
                  pathname: `/advisor/${portfolioId}`,
                  state: { classNavInfo }
                });
              }}
            >
              <img src={user.advisor.image_url} alt="Advisor" />
              <span>Your Advisor</span>
            </a>
          </div>

        </Header>
      );
    }
  }

  componentDidUpdate() {
    window.scrollTo(0, 0);
  }
}

function mapStateToProps(state) {
  const { header, user } = state.auth.data;
  const { asset } = state.fundData;
  return { header, user, asset };
}
export default connect(
  mapStateToProps,
  actions
)(DrillDownDetail);
