import { LOAD_DATA_FUND, LOAD_PUBLIC_FUNDS, LOAD_DATA_ASSET } from '../actions/types';

const INITIAL_STATE = {};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOAD_PUBLIC_FUNDS:
      return {
        ...state,
        error: '',
        authenticated: false,
        publicFunds: action.payload.data.services,
        header: action.payload.data.header
      };
    case LOAD_DATA_FUND:
      return {
        ...state, error: '', authenticated: true, data: action.data, portfolioId: action.data.id
      };

    case LOAD_DATA_ASSET:
      return {
        ...state,
        error: '',
        authenticated: true,
        asset: action.data
      };

    default:
      return state;
  }
}
