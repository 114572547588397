var ROOT_URL_PROD = 'https://api-data.axialwm-client.com/v1';
var FETCH_DATA_PROD = 'user/load';
var RESET_PASSWORD = 'auth/reset-password';
var LOGIN = 'auth/login';
var TOKEN_REFRESH = 'auth/refresh';
var POST_MESSAGE_TO_ADVISOR_URL = 'user/advisor/message';
var FETCH_PUBLIC_INVESTMENT_FUNDS = 'funds/load';
export var URLS = {
  ROOT_URL_PROD: ROOT_URL_PROD,
  FETCH_DATA_PROD: FETCH_DATA_PROD,
  RESET_PASSWORD: RESET_PASSWORD,
  LOGIN: LOGIN,
  TOKEN_REFRESH: TOKEN_REFRESH,
  POST_MESSAGE_TO_ADVISOR_URL: POST_MESSAGE_TO_ADVISOR_URL,
  FETCH_PUBLIC_INVESTMENT_FUNDS: FETCH_PUBLIC_INVESTMENT_FUNDS
};