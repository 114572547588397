import axios from 'axios';
import { URLS } from 'env.js';
import {
  LOAD_PUBLIC_FUNDS
} from './types';


export function fetchPublicInvestmentFunds() {
  return function (dispatch) {
    const url = `${URLS.ROOT_URL_PROD}/${URLS.FETCH_PUBLIC_INVESTMENT_FUNDS}`;
    axios.post(url)
      .then((response) => {
        dispatch({
          type: LOAD_PUBLIC_FUNDS,
          payload: response.data
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };
}
