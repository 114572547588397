import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from 'src/components/header/Header';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as publicInvestmentActions from 'src/actions/PublicInvestmentFundsAction';
import SwiperPublicInvestmentFunds from './SwiperPublicInvesmentFunds';


class PublicInvestmentFunds extends Component {
  static propTypes = {
    publicFunds: PropTypes.array,
    history: PropTypes.object,
    header: PropTypes.object,
    fetchPublicInvestmentFunds: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { fetchPublicInvestmentFunds } = this.props;
    fetchPublicInvestmentFunds();
  }

  render() {
    const { publicFunds, history, header } = this.props;
    if (_.isNil(publicFunds)) {
      return (
        <div>
          <span>Loading</span>
        </div>
      );
    }
    const classNavInfo = { bodyClassName: "page home fimarge" };

    return (
      <Header
        authenticated={false}
        history={history}
        header={header}
        activateSwiper
        classNavInfo={classNavInfo}
        activateButtonBack
      >
        <SwiperPublicInvestmentFunds
          publicFunds={publicFunds}
          headerInfo={header}
          history={history}
        />
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  const { publicFunds, header } = state.fundData;
  return { publicFunds, header };
};
export default connect(mapStateToProps, publicInvestmentActions)(PublicInvestmentFunds);
