/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

const TimeOutContext = React.createContext();

export default class TimeOutProvider extends Component {
  static propTypes = {
    children: PropTypes.object
  }

  state = {
    timerTimeOutInMillis: 120000
  }

  render() {
    const { children } = this.props;
    return (
      <TimeOutContext.Provider value={{
        state: this.state,
        setTimer: (millis) => this.setState({
          timerTimeOutInMillis: millis
        })
      }}
      >
        {children}
      </TimeOutContext.Provider>
    );
  }
}
