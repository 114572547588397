import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as actions from '../../actions';

class SignOut extends PureComponent {
  static propTypes = {
    signOutUser: PropTypes.func
  }

  componentWillMount() {
    const { signOutUser } = this.props;
    signOutUser();
  }

  render() {
    return (
      <div>Sorry to see you leave...</div>
    );
  }
}

export default connect(null, actions)(SignOut);
