import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as consts from 'src/utils/consts';
import PortFolioComponent from './PortFolioComponent';
import { HeaderTime } from "../headerTime/headerTime";

class SwiperPublicInvestmentFunds extends Component {
  static propTypes = {
    publicFunds: PropTypes.array.isRequired,
    headerInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  shouldComponentUpdate() {
    return false;
  }

  componentDidMount() {
    this.swiper = new global.Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet(index, className) {
          const tabsNames = [consts.SWIPE_MTD, consts.SWIPE_YTD, consts.SWIPE_12M];
          const src = `<span class="${className}">${tabsNames[index]}</span>`;
          return src;
        },
      },
      initialSlide: 1,
      autoHeight: true,
      on: {
        init() {
          this.updateAutoHeight();
        },
        click() {
          this.updateAutoHeight();
        },
      },
    });
  }

  componentWillUnmount() {
    this.swiper.destroy();
  }

  renderPeriod(period) {
    const {
      publicFunds, headerInfo, history,
    } = this.props;

    let performancePeriod;

    switch (period) {
      case consts.SWIPE_12M:
        performancePeriod = "performance_12m";
        break;
      case consts.SWIPE_MTD:
        performancePeriod = "performance_mtd";
        break;
      case consts.SWIPE_YTD:
        performancePeriod = "performance_ytd";
        break;
      default:
        break;
    }


    const section2 = !_.isNil(publicFunds) && publicFunds.map((portfolio) => {
      if (portfolio.is_aggregate !== 1) {
        const performance = portfolio.totals[performancePeriod];
        return (
          <PortFolioComponent
            key={portfolio.id}
            nameOfClass="portfolio"
            period={period}
            percentage={performance}
            headerInfo={headerInfo}
            history={history}
            portfolio={portfolio}
            performancePeriod={performancePeriod}
          />
        );
      }

      return <React.Fragment key={new Date()} />;
    });

    return (
      <React.Fragment>
        <HeaderTime header={headerInfo} />
        <section>
          {section2}
        </section>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="main-container">
        <div className="wrapper clearfix">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                {
                  this.renderPeriod(consts.SWIPE_MTD)
                }
              </div>
              <div className="swiper-slide">
                {
                  this.renderPeriod(consts.SWIPE_YTD)
                }
              </div>
              <div className="swiper-slide">
                {
                  this.renderPeriod(consts.SWIPE_12M)
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SwiperPublicInvestmentFunds;
