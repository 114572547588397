import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import AuthReducer from './auth_reducer';
import LoadDataReducer from './load_data_reducer';
import AdvisorReducer from './advisor_reducer';
import TimeOutReducer from './timeOutReducer';

const rootReducer = combineReducers({
  form: formReducer,
  auth: AuthReducer,
  fundData: LoadDataReducer,
  advisor: AdvisorReducer,
  timer: TimeOutReducer
});

export default rootReducer;
