/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PercentageComponent, CurrencyComponent } from 'src/utils/NumberTDStyle';
import * as navigationActions from 'src/actions/NavigationActions';

class PortFolioComponent extends Component {
  static propTypes = {
    title: PropTypes.string,
    period: PropTypes.string.isRequired,
    amount: PropTypes.string,
    percentage: PropTypes.string,
    nameOfClass: PropTypes.string,
    headerInfo: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    portfolioId: PropTypes.number,
    portfolio: PropTypes.object.isRequired,
    performancePeriod: PropTypes.string.isRequired
  }

  onClickFund = (portfolioId) => {
    if (_.isNil(portfolioId)) {
      return;
    }
    const {
      history, headerInfo
    } = this.props;
    const classNavInfo_fundDescription = { bodyClassName: "page detail" };

    const url = '/fundDescription/'.concat(portfolioId);
    if (history) {
      navigationActions.navigateTo(history, {
        pathname: url,
        state: {
          portfolioId, headerInfo, classNavInfo: classNavInfo_fundDescription
        }
      });
    }
  }

  render() {
    const {
      period, nameOfClass, portfolio, performancePeriod
    } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <React.Fragment>
        <a
          onClick={() => this.onClickFund(portfolio.id)}
          className={nameOfClass}
          title={portfolio.code_publish}
        >
          <div className="title">
            {portfolio.code_publish}
          </div>
          <div className="period">
            {period}
          </div>
          <div className="amount">
            <CurrencyComponent
              amount={portfolio.totals.amount}
              strong={false}
              type="div"
              currencyCode={portfolio.currency_code}
            />
          </div>
          <PercentageComponent
            amount={portfolio.totals[performancePeriod]}
            strong={false}
            type="div"
          />
          <span className="detail">Detail  &gt;</span>
        </a>
      </React.Fragment>
    );
  }
}

export default PortFolioComponent;
