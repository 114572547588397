import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Bar, defaults } from 'react-chartjs-2';

export default class BarChart extends Component {
  static propTypes = {
    data: PropTypes.object,
    labels: PropTypes.object,
    values: PropTypes.object,
  }

  componentWillMount() {
    defaults.global.defaultFontColor = '#58585880';
  }

  getBackgroundColors(values) {
    const backgroundColor = [];
    values.forEach(value => {
      const positiveColor = '#104b7f';
      const negativeColor = '#34b4e4';
      const isPositive = value >= 0;
      backgroundColor.push((isPositive) ? positiveColor : negativeColor);
    });

    return backgroundColor;
  }

  render() {
    const { data: { labels, values } } = this.props;
    const numberList = values.map((textNumber) => parseInt(textNumber, 10));

    const options = {
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{
          type: 'time',
          time: {
            parser: 'YYYY-MM', // we ignore the day
            unit: 'month',
            displayFormats: {
              month: 'MMM YYYY',
            },
            tooltipFormat: 'MMMM YYYY',
          },
          gridLines: {
            display: false,
          },
          offset: true,
        }],
      },
      tooltips: {
        backgroundColor: '#104b7f',
        titleFontStyle: 'normal',
        bodyFontStyle: 'bold',
        yPadding: 10,
        xPadding: 10,
        cornerRadius: 0,
        displayColors: false,
        borderColor: '#fff',
        borderWidth: '1',
        callbacks: {
          label(tooltipItem, data) {
            const label = data.labels[tooltipItem.index];
            const percent = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
            return `${label}  ${Math.round(percent * 100) / 100}%`;
          },
        },
      },
    };

    if (!numberList || !labels) {
      return <div />;
    }

    const data = {
      labels,
      datasets: [{
        data: values,
        backgroundColor: this.getBackgroundColors(values),
      }],
    };

    return (
      <div>
        <Bar
          data={data}
          width={960}
          height={479}
          options={options}
        />
      </div>
    );
  }
}
